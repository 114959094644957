var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('title-row', {
    staticClass: "pt-16",
    attrs: {
      "title": "UNIOM beléptetőrendszer"
    }
  }), _c('div', {
    staticClass: "normal-text mb-8"
  }, [_vm._v(" A rendszer használatához válasszon a bal oldali menüpontok közül! ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }